<template>
  <!-- Footer container -->
  <footer class="bg-blue-900 text-neutral-50 text-left w-full">
    <div class="mx-auto max-w-screen-xl p-4">
      <!-- Main container div -->
      <div class="flex flex-col">
        <div class="flex flex-row flex-wrap gap-4 font-bold justify-start sm:flex-nowrap">
          <NuxtLink to="/" class="hover:underline">首頁</NuxtLink>

          <NuxtLink to="/account-center" class="hover:underline"> 帳戶搜尋 </NuxtLink>

          <!-- <NuxtLink to="/leaderboard" class="hover:underline"> 排行榜單 </NuxtLink> -->

          <NuxtLink to="/learn" class="hover:underline">知識中心</NuxtLink>

          <NuxtLink to="/about" class="hover:underline">關於iPro</NuxtLink>

          <NuxtLink to="/contact" class="hover:underline">聯絡我們</NuxtLink>
        </div>
      </div>
    </div>

    <!-- Copyright section -->
    <div class="mx-auto max-w-screen-xl pb-4 px-4 text-center">
      <hr />
      <div class="pt-1">
        <p class="text-neutral-50 text-center">
          重要聲明：本網站提供的數據、分析工具及文章僅供參考，投資決策請以官方資訊為準，並自行承擔風險。
        </p>
        <div class="flex flex-row gap-4 justify-center">
          <span>© iPro 2024</span>
          <NuxtLink to="/privacy-policy" class="hover:underline">隱私權政策</NuxtLink>

          <NuxtLink to="/disclaimer-and-copyright" class="hover:underline"
            >免責聲明與著作權</NuxtLink
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup></script>
