<template>
  <nav class="sticky top-0 z-50 w-full bg-white shadow-sm">
    <div class="flex max-w-screen-xl mx-auto justify-between items-center px-4">
      <!-- Logo Section -->
      <div class="flex items-center">
        <NuxtLink to="/" class="flex items-center">
          <img src="/iPro_logo.png" class="h-16" alt="iPro Logo" />
        </NuxtLink>
      </div>

      <!-- Desktop Navigation -->
      <div class="hidden md:flex md:items-center md:space-x-6 font-bold">
        <NuxtLink
          to="/account-center"
          class="py-2 text-neutral-800 hover:text-blue-700 transition-colors"
        >
          帳戶搜尋
        </NuxtLink>

        <!-- Leaderboard Dropdown -->
        <!-- <div class="relative group">
          <button
            class="flex items-center py-2 text-neutral-800 hover:text-blue-700 transition-colors group-hover:text-blue-700"
          >
            排行榜單
            <UIcon
              name="ic:baseline-keyboard-arrow-down"
              class="w-5 h-5 ml-1 transition-transform duration-200 group-hover:rotate-180"
            />
          </button>
          <div
            class="invisible group-hover:visible opacity-0 group-hover:opacity-100 absolute left-0 w-48 mt-2 bg-white rounded-md shadow-lg transition-all duration-200"
          >
            <NuxtLink
              to="/leaderboard"
              class="block px-4 py-2 text-base text-neutral-800 hover:bg-neutral-50 hover:text-blue-700"
            >
              淨值榜
            </NuxtLink>
            <NuxtLink
              to="/leaderboard"
              class="block px-4 py-2 text-base text-neutral-800 hover:bg-neutral-50 hover:text-blue-700"
            >
              績效榜
            </NuxtLink>
            <NuxtLink
              to="/leaderboard"
              class="block px-4 py-2 text-base text-neutral-800 hover:bg-neutral-50 hover:text-blue-700"
            >
              撥回榜
            </NuxtLink>
          </div>
        </div> -->

        <!-- Knowledge Center Dropdown -->
        <div class="relative group">
          <button
            class="flex items-center py-2 text-neutral-800 hover:text-blue-700 transition-colors group-hover:text-blue-700"
          >
            知識中心
            <UIcon
              name="ic:baseline-keyboard-arrow-down"
              class="w-5 h-5 ml-1 transition-transform duration-200 group-hover:rotate-180"
            />
          </button>
          <div
            class="invisible group-hover:visible opacity-0 group-hover:opacity-100 absolute left-0 w-48 mt-2 bg-white rounded-md shadow-lg transition-all duration-200"
          >
            <NuxtLink
              to="/learn"
              class="block px-4 py-2 text-base text-neutral-800 hover:bg-neutral-50 hover:text-blue-700"
            >
              學習文章
            </NuxtLink>
            <NuxtLink
              to="/learn/glossary"
              class="block px-4 py-2 text-base text-neutral-800 hover:bg-neutral-50 hover:text-blue-700"
            >
              專業詞彙
            </NuxtLink>
          </div>
        </div>

        <NuxtLink to="/about" class="py-2 text-neutral-800 hover:text-blue-700 transition-colors">
          關於iPro
        </NuxtLink>
        <NuxtLink to="/contact" class="py-2 text-neutral-800 hover:text-blue-700 transition-colors">
          聯絡我們
        </NuxtLink>
      </div>

      <!-- Mobile Menu Button -->
      <button
        class="inline-flex items-center p-2 text-neutral-800 rounded-lg md:hidden hover:bg-gray-100"
        @click="toggleMobileMenu"
        aria-label="Toggle mobile menu"
      >
        <UIcon :name="isMobileMenuOpen ? 'heroicons:x-mark' : 'heroicons:bars-3'" class="h-6 w-6" />
      </button>
    </div>

    <!-- Mobile Menu -->
    <div v-show="isMobileMenuOpen" class="absolute w-full bg-white shadow-lg md:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1">
        <NuxtLink
          to="/account-center"
          class="block px-3 py-2 rounded-md text-base font-medium text-neutral-800 hover:bg-neutral-50 hover:text-blue-700"
          @click="closeMobileMenu"
        >
          帳戶搜尋
        </NuxtLink>

        <!-- Mobile Leaderboard Section -->
        <div class="space-y-1">
          <button
            class="flex items-center justify-between w-full px-3 py-2 text-base font-medium text-neutral-800 rounded-md hover:bg-neutral-50 hover:text-blue-700"
            @click="isMobileLeaderboardOpen = !isMobileLeaderboardOpen"
          >
            <span>排行榜單</span>
            <UIcon
              name="ic:baseline-keyboard-arrow-down"
              class="w-5 h-5 transition-transform duration-200"
              :class="{ 'rotate-180': isMobileLeaderboardOpen }"
            />
          </button>
          <div v-show="isMobileLeaderboardOpen" class="pl-4">
            <NuxtLink
              to="/leaderboard"
              class="block px-3 py-2 text-base font-medium text-neutral-800 rounded-md hover:bg-neutral-50 hover:text-blue-700"
              @click="closeMobileMenu"
            >
              淨值榜
            </NuxtLink>
            <NuxtLink
              to="/leaderboard"
              class="block px-3 py-2 text-base font-medium text-neutral-800 rounded-md hover:bg-neutral-50 hover:text-blue-700"
              @click="closeMobileMenu"
            >
              績效榜
            </NuxtLink>
            <NuxtLink
              to="/leaderboard"
              class="block px-3 py-2 text-base font-medium text-neutral-800 rounded-md hover:bg-neutral-50 hover:text-blue-700"
              @click="closeMobileMenu"
            >
              撥回榜
            </NuxtLink>
          </div>
        </div>

        <!-- Mobile Knowledge Center Section -->
        <div class="space-y-1">
          <button
            class="flex items-center justify-between w-full px-3 py-2 text-base font-medium text-neutral-800 rounded-md hover:bg-neutral-50 hover:text-blue-700"
            @click="isMobileKnowledgeOpen = !isMobileKnowledgeOpen"
          >
            <span>知識中心</span>
            <UIcon
              name="ic:baseline-keyboard-arrow-down"
              class="w-5 h-5 transition-transform duration-200"
              :class="{ 'rotate-180': isMobileKnowledgeOpen }"
            />
          </button>
          <div v-show="isMobileKnowledgeOpen" class="pl-4">
            <NuxtLink
              to="/learn"
              class="block px-3 py-2 text-base font-medium text-neutral-800 rounded-md hover:bg-neutral-50 hover:text-blue-700"
              @click="closeMobileMenu"
            >
              學習文章
            </NuxtLink>
            <NuxtLink
              to="/learn/glossary"
              class="block px-3 py-2 text-base font-medium text-neutral-800 rounded-md hover:bg-neutral-50 hover:text-blue-700"
              @click="closeMobileMenu"
            >
              專業詞彙
            </NuxtLink>
          </div>
        </div>

        <NuxtLink
          to="/about"
          class="block px-3 py-2 rounded-md text-base font-medium text-neutral-800 hover:bg-neutral-50 hover:text-blue-700"
          @click="closeMobileMenu"
        >
          關於iPro
        </NuxtLink>
        <NuxtLink
          to="/contact"
          class="block px-3 py-2 rounded-md text-base font-medium text-neutral-800 hover:bg-neutral-50 hover:text-blue-700"
          @click="closeMobileMenu"
        >
          聯絡我們
        </NuxtLink>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
const isMobileMenuOpen = ref(false)
const isMobileLeaderboardOpen = ref(false)
const isMobileKnowledgeOpen = ref(false)

const toggleMobileMenu = () => {
  isMobileMenuOpen.value = !isMobileMenuOpen.value
  if (!isMobileMenuOpen.value) {
    isMobileLeaderboardOpen.value = false
    isMobileKnowledgeOpen.value = false
  }
}

const closeMobileMenu = () => {
  isMobileMenuOpen.value = false
  isMobileLeaderboardOpen.value = false
  isMobileKnowledgeOpen.value = false
}
</script>
