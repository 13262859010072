<template>
  <div class="flex flex-col min-h-screen">
    <layout-header />

    <main :key="route.fullPath" class="flex-1 bg-blue-50">
      <slot />
    </main>

    <layout-footer />
  </div>
</template>

<script setup lang="ts">
// useSeoMeta({
//   applicationName: "IPro",
//   ogUrl: "IPro",
//   author: "愛普羅",
//   title: "首頁",
//   ogTitle: "首頁",
//   description: '最豐富的類全委帳戶資料',
//   ogDescription: '最豐富的類全委帳戶資料',
//   ogType: "website",
//   ogLocale: "zh_TW",
// });

const route = useRoute()

watch(route, () => {
  useHead({
    title: `${route.meta.title}`,
    titleTemplate: (titleChunk) => {
      return titleChunk ? `${titleChunk} | iPro` : 'iPro'
    },
    meta: [
      { name: 'description', content: `${route.meta.description}` },
      { name: 'author', content: 'iPro' },
      { property: 'og:title', content: `${route.meta.title} | iPro` }
    ]
  })
})
</script>
